/**
 * マスタ関連のコマンドを集約するファイル
 */

import ConfigurationBuilder from "./api/configuration_builder";
import { JsMasterApi } from "~/plugins/openapi/api";

/**
 * カードマスタを取得するコマンド
 */
export class GetCardMasterCommand {
  /**
   * @param {string} targetYear
   * @param {JsMasterApi} api
   */
  constructor(
    targetYear = GetCardMasterCommand.defaultTargetYear,
    timestamp = GetCardMasterCommand.defaultTimestamp,
    api = new JsMasterApi(new ConfigurationBuilder().buildDefault())
  ) {
    this.targetYear = targetYear;
    this.timestamp = timestamp;
    this.api = api;
  }

  /**
   * @returns {Promise<{
   *   m_card_series?: {[key: string]: {
   *     created_at: string,
   *     id: string,
   *     is_coin_gacha: string,
   *     is_crystal_gacha: string,
   *     is_ticket_gacha: string,
   *     merge_series_id: string | null,
   *     sort: string,
   *     target_year: string,
   *     title: string,
   *     updated_at: string,
   *   }},
   *   m_cards?: {[key: string]: {
   *     back_image_key: string | null,
   *     club_id: number,
   *     created_date: string,
   *     cs_id: number,
   *     id: number,
   *     image_key: string,
   *     is_exclude_complete: number,
   *     keiyaku_flg: number,
   *     pd_created_date: string,
   *     pd_id: number,
   *     pd_image_key: string,
   *     pd_name: string,
   *     pd_updated_at: string,
   *     publish_at: string | null,
   *     rare_id: number,
   *     real_club_id: number,
   *     title: string,
   *     tradable_flg: number,
   *     updated_at: string,
   *     video_flg: string,
   *   }},
   *   m_cards_state?: {
   *     card_back_path: string,
   *     card_base_uri: string,
   *     card_count: number,
   *     card_front_path: string,
   *     card_personal_path: string,
   *     card_video_path: string,
   *     thumb_file_suffix: string,
   *   },
   *   m_clubs?: {[key: string]: {
   *     area_id: string,
   *     category_id: string,
   *     club_name_short: string,
   *     id: string,
   *     sort_order: string,
   *   }},
   *   m_personal?: {[key: string]: {
   *     club_id: string,
   *     id: string,
   *     name: string,
   *     position: string,
   *     uniform_num: string,
   *     uniform_num_sort: string,
   *   }},
   *   m_position?: {[key: string]: string},
   * }>}
   */
  execute() {
    return this.api
      .jsMasterCardsJsonTargetYearGet(this.targetYear,{
        params:{
          t:this.timestamp
        },
        withCredentials:false
      })
      .then((res) => res.data)
      .catch((error) => {
        console.error(error);
        return {};
      });
  }

  static defaultTargetYear = "2023"
  static defaultTimestamp = ""
}

export class GetNftCardMasterCommand {
  /**
   * @param {JsMasterApi} api
   */
  constructor(
      timestamp = GetCardMasterCommand.defaultTimestamp,
      api = new JsMasterApi(new ConfigurationBuilder().buildDefault())
  ) {
      this.timestamp = timestamp;
    this.api = api;
  }

  /**
   * @returns {Promise<{
   *   m_card_series?: {[key: string]: {
   *     created_at: string,
   *     id: string,
   *     is_coin_gacha: string,
   *     is_crystal_gacha: string,
   *     is_ticket_gacha: string,
   *     merge_series_id: string | null,
   *     sort: string,
   *     target_year: string,
   *     title: string,
   *     updated_at: string,
   *   }},
   *   m_cards?: {[key: string]: {
   *     back_image_key: string | null,
   *     club_id: number,
   *     created_date: string,
   *     cs_id: number,
   *     id: number,
   *     image_key: string,
   *     is_exclude_complete: number,
   *     keiyaku_flg: number,
   *     pd_created_date: string,
   *     pd_id: number,
   *     pd_image_key: string,
   *     pd_name: string,
   *     pd_updated_at: string,
   *     publish_at: string | null,
   *     rare_id: number,
   *     real_club_id: number,
   *     title: string,
   *     tradable_flg: number,
   *     updated_at: string,
   *     video_flg: string,
   *   }},
   *   m_cards_state?: {
   *     card_back_path: string,
   *     card_base_uri: string,
   *     card_count: number,
   *     card_front_path: string,
   *     card_personal_path: string,
   *     card_video_path: string,
   *     thumb_file_suffix: string,
   *   },
   *   m_clubs?: {[key: string]: {
   *     area_id: string,
   *     category_id: string,
   *     club_name_short: string,
   *     id: string,
   *     sort_order: string,
   *   }},
   *   m_personal?: {[key: string]: {
   *     club_id: string,
   *     id: string,
   *     name: string,
   *     position: string,
   *     uniform_num: string,
   *     uniform_num_sort: string,
   *   }},
   *   m_position?: {[key: string]: string},
   * }>}
   */
  execute() {
    return this.api
        .jsMasterNftCardsJsonGet({
          params:{
            t:this.timestamp
          },
          withCredentials:false
        })
        .then((res) => res.data)
        .catch((error) => {
          console.error(error);
          return {};
        });
  }
  static defaultTimestamp = ""
}

