import { GetCardMasterCommand } from "../commands/js_master";

export default class CardMaster {
  static CURRENT_YEAR = "2023";
  static CARD_MASTER_UPDATED_AT = "";

  /**
   * @type {{
   *   [year: string]: {
   *     timestamp: string,
   *     loading: boolean
   *   }
   * }}
   */
  static _status = {};

  /**
   * @type {{
   *   [year: string]: {
   *     [key: string]: {
   *       created_at: string,
   *       id: string,
   *       is_coin_gacha: string,
   *       is_crystal_gacha: string,
   *       is_ticket_gacha: string,
   *       merge_series_id: string | null,
   *       sort: string,
   *       target_year: string,
   *       title: string,
   *       updated_at: string,
   *     }
   *   }
   * }}
   */
  static _card_series = {};

  /**
   * @type {{
   *   [year: string]: {
   *     [key: string]: {
   *       back_image_key: string | null,
   *       club_id: number,
   *       created_date: string,
   *       cs_id: number,
   *       id: number,
   *       image_key: string,
   *       is_exclude_complete: number,
   *       keiyaku_flg: number,
   *       pd_created_date: string,
   *       pd_id: number,
   *       pd_image_key: string,
   *       pd_name: string,
   *       pd_updated_at: string,
   *       publish_at: string | null,
   *       rare_id: number,
   *       real_club_id: number,
   *       title: string,
   *       tradable_flg: number,
   *       updated_at: string,
   *       video_flg: string,
   *     }
   *   }
   * }}
   */
  static _cards = {};

  /**
   * @type {{
   *   [year: string]: {
   *     card_back_path: string,
   *     card_base_uri: string,
   *     card_count: number,
   *     card_front_path: string,
   *     card_personal_path: string,
   *     card_video_path: string,
   *     thumb_file_suffix: string,
   *   }
   * }}
   */
  static _cards_state = {};

  /**
   * @type {{
   *   [year: string]: {
   *     [key: string]: {
   *       area_id: string,
   *       category_id: string,
   *       club_name_short: string,
   *       id: string,
   *       sort_order: string,
   *     }
   *   }
   * }}
   */
  static _clubs = {};

  /**
   * @type {{
   *   [year: string]: {
   *     [key: string]: {
   *       club_id: string,
   *       id: string,
   *       name: string,
   *       position: string,
   *       uniform_num: string,
   *       uniform_num_sort: string,
   *     }
   *   }
   * }}
   */
  static _personal = {};

  /**
   * @type {{[year: string]: {[key: string]: string}}}
   */
  static _position = {};


  /**
   * @param {string} year
   * @returns {{
   *   [key: string]: {
   *     created_at: string,
   *     id: string,
   *     is_coin_gacha: string,
   *     is_crystal_gacha: string,
   *     is_ticket_gacha: string,
   *     merge_series_id: string | null,
   *     sort: string,
   *     target_year: string,
   *     title: string,
   *     updated_at: string,
   *   }
   * } | null}
   */
  static getCardSeries(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._card_series[year] || null;
  }

  /**
   * @param {string} year
   * @returns {{
   *   [key: string]: {
   *     back_image_key: string | null,
   *     club_id: number,
   *     created_date: string,
   *     cs_id: number,
   *     id: number,
   *     image_key: string,
   *     is_exclude_complete: number,
   *     keiyaku_flg: number,
   *     pd_created_date: string,
   *     pd_id: number,
   *     pd_image_key: string,
   *     pd_name: string,
   *     pd_updated_at: string,
   *     publish_at: string | null,
   *     rare_id: number,
   *     real_club_id: number,
   *     title: string,
   *     tradable_flg: number,
   *     updated_at: string,
   *     video_flg: string,
   *   }
   * } | null}
   */
  static getCards(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._cards[year] || null;
  }

  /**
   * @param {string} year
   * @returns {{
   *   card_back_path: string,
   *   card_base_uri: string,
   *   card_count: number,
   *   card_front_path: string,
   *   card_personal_path: string,
   *   card_video_path: string,
   *   thumb_file_suffix: string,
   * } | null}
   */
  static getCardsState(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._cards_state[year] || null;
  }

  /**
   * @param {string} year
   * @returns {{
   *   [key: string]: {
   *     area_id: string,
   *     category_id: string,
   *     club_name_short: string,
   *     id: string,
   *     sort_order: string,
   *   }
   * } | null}
   */
  static getClubs(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._clubs[year] || null;
  }

  /**
   * @param {string} year
   * @returns {{
   *   [key: string]: {
   *     club_id: string,
   *     id: string,
   *     name: string,
   *     position: string,
   *     uniform_num: string,
   *     uniform_num_sort: string,
   *   }
   * } | null}
   */
  static getPersonal(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._personal[year] || null;
  }

  /**
   * @param {string} year
   * @returns {{[key: string]: string} | null}
   */
  static getPosition(year = CardMaster.CURRENT_YEAR) {
    return CardMaster._position[year] || null;
  }
  
  /**
   * @param {string} year
   * @param {string} timestamp
   * @param {boolean} loading
   */
  static setStatus(year, timestamp, loading) {
    if (CardMaster._status[year]) {
      CardMaster._status[year].timestamp = timestamp;
      CardMaster._status[year].loading = loading;
    } else {
      CardMaster._status[year] = {timestamp, loading};
    }
  }

  /**
   * @param {string} year
   * @return {{timestamp:string, loading: boolean}}
   */
  static getStatus(year) {
    return CardMaster._status[year];
  }
  
  /**
   * マスタのキャッシュを更新する
   * @param {string} year
   */
  static async update(year = CardMaster.CURRENT_YEAR, timestamp = CardMaster.CARD_MASTER_UPDATED_AT) {
    const status = CardMaster.getStatus(year);
    if (status && status.timestamp == timestamp) {
      // 取得中の場合待機
      while (status.loading) {
        await new Promise(resolve => {
          setTimeout(resolve, 100);
        });
      }
    } else {
      const old_timestamp = status?.timestamp;
      console.log("load card master", year, timestamp);
      CardMaster.setStatus(year, timestamp, true);
      return new GetCardMasterCommand(year, timestamp).execute().then(res => {
        if (res.m_card_series) CardMaster._card_series[year] = res.m_card_series;
        if (res.m_cards) CardMaster._cards[year] = res.m_cards;
        if (res.m_cards_state) CardMaster._cards_state[year] = res.m_cards_state;
        if (res.m_clubs) CardMaster._clubs[year] = res.m_clubs;
        if (res.m_personal) CardMaster._personal[year] = res.m_personal;
        if (res.m_position) CardMaster._position[year] = res.m_position;
        CardMaster.setStatus(year, timestamp, false);
        return res;
      }).catch((e) => {
        // エラー発生時には取得前の状態に復帰
        console.error(e);
        CardMaster.setStatus(year, old_timestamp, false);
      });
    }
  }
}
